import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Control } from 'react-hook-form';

import CharacterCounterRich from '../forms/CharacterCounterRich';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import RichTextArea from '../forms/RichTextArea';
import TextField from '../forms/TextField';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import {
  validateRequired,
  validateRichTextLength,
} from '../../utils/validations';

interface Props {
  control: Control<DetailedSheetFieldValuesInterface>;
}

const DetailedSheetDescriptionsForm: FC<Props> = ({ control }) => {
  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12}>
          <RichTextArea
            control={control}
            name="websiteDescription"
            label="Texte descriptif complet, destiné à votre fiche en ligne (300 caractères max)"
            rules={validateRichTextLength({ max: 300, required: true })}
          />

          <CharacterCounterRich
            control={control}
            name="websiteDescription"
            max={300}
          />
        </Grid>

        <Grid item xs={12}>
          <RichTextArea
            control={control}
            name="highlightsDescription"
            label="Nouveautés (facultatif, 200 caractères max)"
            rules={validateRichTextLength({ max: 200 })}
          />

          <CharacterCounterRich
            control={control}
            name="highlightsDescription"
            max={200}
          />
        </Grid>

        <Grid item xs={12}>
          <RichTextArea
            control={control}
            name="sustainableSolutionDescription"
            label="Solution durable (facultatif, 200 caractères max)"
            rules={validateRichTextLength({ max: 200 })}
          />

          <CharacterCounterRich
            control={control}
            name="sustainableSolutionDescription"
            max={200}
          />
        </Grid>

        <Grid item xs={12}>
          <RichTextArea
            control={control}
            name="contestDescription"
            label="Concours (facultatif, 200 caractères max)"
            rules={validateRichTextLength({ max: 200 })}
          />

          <CharacterCounterRich
            control={control}
            name="contestDescription"
            max={200}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            control={control}
            label={
              <>
                {'Mots-clés'}
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Inscrivez ici jusqu&apos;à 5 mots-clés, séparés par des points
                  virgules, qui décrivent vos produits et services (ex.:
                  rénovation; toiture; design d&apos;intérieur; bois; finition;
                  maison neuve; etc.).
                </Typography>
              </>
            }
            name="tags"
            rules={validateRequired()}
            fullWidth
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default DetailedSheetDescriptionsForm;
