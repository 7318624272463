import Grid from '@mui/material/Grid';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import RadioButtons from '../forms/RadioButtons';
import Select from '../forms/Select';
import TimePicker from '../forms/TimePicker';
import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import DatePeriodsEnum from '../../enums/date-periods.enum';
import OptionInterface from '../../interfaces/option.interface';
import AdminAssemblyFieldValues from '../../interfaces/assemblies/admin-assembly-field-values.interface';
import { validateRequired } from '../../utils/validations';
import {
  ASSEMBLY_DELIVERY_DATES,
  ASSEMBLY_DELIVERY_DATE_KITCHEN,
} from '../../values';

const DELIVERY_DATE_OPTIONS: OptionInterface[] = [
  { label: 'Sélectionner', value: '' },
  ASSEMBLY_DELIVERY_DATE_KITCHEN,
  ...ASSEMBLY_DELIVERY_DATES,
];

interface Props {
  assembly: AssemblyDto;
  profile: ProfileDto;
}

const SECTION_CODE_KITCHEN = 'SCU';

const AdminAssemblyDeliveryPreferenceForm: FC<Props> = ({
  assembly,
  profile,
}) => {
  const { control } = useFormContext<AdminAssemblyFieldValues>();

  const deliveryDateOptions = useMemo(() => {
    const options: OptionInterface[] = [{ label: 'Sélectionner', value: '' }];

    if (profile.section?.code === SECTION_CODE_KITCHEN) {
      options.push(ASSEMBLY_DELIVERY_DATE_KITCHEN);
    }

    return [...options, ...ASSEMBLY_DELIVERY_DATES];
  }, [profile]);

  const renderDeliveryDatePreference = useMemo(() => {
    if (!assembly.deliveryDatePreference) return null;

    return (
      <>
        <Grid item xs={12}>
          <Grid item xs={12} md={6}>
            <Select
              control={control}
              name="deliveryDatePreference"
              label="Journée et période désirées"
              options={deliveryDateOptions}
              rules={validateRequired()}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} md={6}>
            <RadioButtons
              control={control}
              name="deliveryDatePeriodPreference"
              options={[
                { label: 'En matinée', value: DatePeriodsEnum.AM },
                { label: 'En après-midi', value: DatePeriodsEnum.PM },
              ]}
              rules={validateRequired()}
              row
            />
          </Grid>
        </Grid>
      </>
    );
  }, [assembly, deliveryDateOptions, control]);

  return (
    <FormStack>
      <FormGrid>
        {renderDeliveryDatePreference}

        <Grid item xs={12} md={4}>
          <Select
            control={control}
            name="deliveryDate"
            label="Confirmation de la journée"
            options={DELIVERY_DATE_OPTIONS}
            rules={validateRequired()}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TimePicker
            control={control}
            name="deliveryTime"
            label="Confirmation de l'heure"
            rules={validateRequired()}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AdminAssemblyDeliveryPreferenceForm;
