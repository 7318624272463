import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC, useEffect } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import RadioButtons from '../forms/RadioButtons';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Select from '../forms/Select';
import TextField from '../forms/TextField';
import BookingDto from '../../dto/bookings/out/booking.dto';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import { PATHS } from '../../utils/paths';
import { PROVINCES_OPTIONS } from '../../utils/provinces';
import {
  validateEmail,
  validatePhoneNumberFormat,
  validatePostalCodeFormat,
  validateRbqMemberNumber,
  validateRequired,
} from '../../utils/validations';

const OPTIONS = [{ label: 'Aucune', value: '' }, ...PROVINCES_OPTIONS];

interface Props {
  booking?: BookingDto;
  control: Control<DetailedSheetFieldValuesInterface>;
  setValue: UseFormSetValue<DetailedSheetFieldValuesInterface>;
}

const DetailedSheetCompanyForm: FC<Props> = ({
  booking,
  control,
  setValue,
}) => {
  const isRbqMember = useWatch({ name: 'isRbqMember', control }) === 'true';

  useEffect(() => {
    if (!isRbqMember) setValue('rbqMemberNumber', '');
  }, [isRbqMember, setValue]);

  return (
    <>
      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <ReadonlyFieldLabel>Emplacement officiel</ReadonlyFieldLabel>

            <Typography>{booking?.mainLocation ?? 'En traitement'}</Typography>

            <Alert
              severity="warning"
              sx={{ my: 3, boxShadow: 'none', p: '24px !important' }}
            >
              <Link
                href={PATHS.documents.detailedSheetWebExample.path} 
                target="_blank"
              >
                Exemple d&lsquo;une fiche-exposant</Link>{' '}telle qu&lsquo;elle sera publiée sur le nouveau site web d&lsquo;Expo habitat Québec.
            </Alert>

            <Alert
              severity="info"
              sx={{ mb: 3, boxShadow: 'none', p: '24px !important' }}
            >
              Vous souhaitez donner davantage de visibilité à votre entreprise?
              <br />
              <Link
                href={PATHS.documents.visibilityRates.path}
                target="_blank"
              >
                Voici comment vous pourriez augmenter encore plus votre
                rayonnement!
              </Link>{' '}
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <TextField
              control={control}
              fullWidth={false}
              label={
                <>
                  {"Nom de l'entreprise"}
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    (celui que vous souhaitez voir inscrit sur votre
                    fiche-exposant)
                  </Typography>
                </>
              }
              name="companyName"
              rules={validateRequired()}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Les informations ci-dessous ne sont pas obligatoires. N&lsquo;inscrivez
              que celles que vous souhaitez voir apparaître sur votre
              fiche-exposant.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              control={control}
              label="Adresse (facultatif)"
              name="address"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              control={control}
              fullWidth
              label="Ville (facultatif)"
              name="city"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Select
                control={control}
                label="Province (facultatif)"
                name="province"
                options={OPTIONS}
              />
            </FormControl>
          </Grid>

          <Grid item sm={4}>
            <TextField
              control={control}
              fullWidth
              label="Code postal (facultatif)"
              name="postalCode"
              placeholder="ex: G1G 1G1"
              rules={validatePostalCodeFormat(true)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              control={control}
              label="Adresse courriel (facultatif)"
              name="email"
              rules={validateEmail(true)}
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: { xs: 'none', sm: 'inherit' } }}
          />

          <Grid item xs={12} sm={8} md={4}>
            <TextField
              control={control}
              fullWidth
              label="Téléphone (facultatif)"
              name={`phoneNumber`}
              placeholder="Ex: 418 111-2222"
              rules={validatePhoneNumberFormat(true)}
            />
          </Grid>

          <Grid item xs={8} sm={4} md={3}>
            <TextField
              control={control}
              label="Poste (facultatif)"
              placeholder="Ex: 1234"
              name={`phoneNumberExt`}
            />
          </Grid>

          <Grid item xs={12}>
            <RadioButtons
              control={control}
              label={
                <>
                  {'Avez-vous un numéro de licence RBQ ?'}
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    (Obligatoire si vous détenez un numéro de licence RBQ –{' '}
                    <Link
                      href="https://www.rbq.gouv.qc.ca/licence/repondre-a-ses-obligations/afficher-votre-licence/"
                      target="_blank"
                    >
                      cliquez pour plus de détails
                    </Link>
                    )
                  </Typography>
                </>
              }
              name="isRbqMember"
              options={[
                { label: 'Oui', value: 'true' },
                { label: 'Non', value: 'false' },
              ]}
              rules={validateRequired()}
              row
            />
          </Grid>

          {isRbqMember && (
            <Grid item xs={12}>
              <TextField
                control={control}
                label="Numéro de licence RBQ"
                name="rbqMemberNumber"
                rules={validateRbqMemberNumber(isRbqMember)}
              />
            </Grid>
          )}
        </FormGrid>
      </FormStack>
    </>
  );
};

export default DetailedSheetCompanyForm;
